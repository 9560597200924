import * as React from "react";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";

import { IHomeProps } from "../../pages/PREVIOUS-index";
import { DynamicImageSrcString } from "../../templates/utils/dynamicImage";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { FullWidthColumnWrapper } from "../confines-wrappers";
import HeroVideo from "../hero-video";
import Link from "../link";
import Logo from "../logo";
import PageTop from "../page-top";

interface IProps {
  data: IHomeProps;
}

/**
 * Home hero
 */
const HomeHero: React.FC<IProps> = ({ data }) => {
  const { width } = useWindowDimensions();
  const [ logoPosition ] = React.useState(false);

  /**
   * Dynamic background image depending the devices width
   */
  const fallbackImg = React.useMemo(() => {
    if (width && width < 600) {
      return DynamicImageSrcString(data.nodeHome.relationships.field_mobile_hero_image);
    }

    return DynamicImageSrcString(data.nodeHome.relationships.field_hero_image);
  }, [
    data.nodeHome.relationships.field_hero_image,
    data.nodeHome.relationships.field_mobile_hero_image,
    width
  ]);

  return (
    <PageTopCustom id="hero">
      <PageTop
        style={{
          paddingTop: 0,
          paddingBottom: 0
        }}
        height="medium"
      >
        <HeroVideo
          src={data.nodeHome.field_hero_video_url}
          fallbackImage={fallbackImg}
          contrastGradient
        />

        <FullWidthColumnWrapper
          className={`top-content ${logoPosition ? "fixed" : "static"}`}
        >
          <div
            id="logo-hero"
            className="logo-wrapper"
          >
            <Logo type="light" />
          </div>

          <p
            id="heading"
            className="heading"
          >
            {data.nodeHome.field_hero_heading}
          </p>

          <ul className="links">
            {data.nodeHome.field_landing_page_links.map((link, i) => {
              return (
                <li key={i}>
                  <Link href={link.uri}>
                    {link.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </FullWidthColumnWrapper>
      </PageTop>
    </PageTopCustom>
  );
};

export default HomeHero;

export const PageTopCustom = styled.div`
  .top-content-container {
    .top-content-wrapper {
      position: absolute;
      left: 0;
      top: 0;

      .top-content {
        position: relative;
        z-index: 4;

        .logo-wrapper {
          display: none;
          position: absolute;
          top: 25.4765625px;
          width: 16rem;
        }

        .heading {
          transition: 0.2s ease-in-out;
          padding-top: 0;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }

        .links {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          position: absolute;
          left: -100vw;
          width: 100vw;
          height: 10rem;
          padding: 1.8rem 0 0;
          list-style: none;
          overflow: hidden;

          li {
            transition: 0.4s ease-in-out !important;
            display: block;
            position: relative;
            left: auto;
            padding-top: 1rem;

            &:last-child {
              padding-right: 0;
            }

            a {
              display: block;
              padding: 0.6rem 0.8rem;
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};
              background-color: rgba(0, 0, 0, 0.7);
              border: none;
              border-radius: 0.4rem;

              &:hover {
                color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 375px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            max-width: 43.2rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            max-width: 41.6rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          }

          .links {
            position: relative;
            left: auto;
            max-width: 44rem;

            li {
              &:nth-child(1n) {
                left: auto;
                padding-right: 4%;
              }

              &:nth-child(4) {
                padding-right: 0;
              }

              &:nth-child(1n + 5) {
                left: -100vw;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 568px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            max-width: 51.4rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }

          .links {
            max-width: 52.8rem;

            li {
              &:nth-child(1n) {
                left: auto;
                padding-right: 4%;
              }

              &:nth-child(6) {
                padding-right: 0;
              }

              &:nth-child(1n + 7) {
                left: -100vw;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .links {
            padding-top: 2.4rem;
            max-width: 56rem;
            height: 11.6rem;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .logo-wrapper {
            display: block;
          }

          .heading {
            padding-top: 8.105rem;
            max-width: 71.4rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          }

          .links {
            max-width: 72.8rem;

            li {
              &:nth-child(1n) {
                left: auto;
                padding-right: 2.2rem;
              }

              &:nth-child(8) {
                padding-right: 0;
              }

              &:nth-child(1n + 9) {
                left: -100vw;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            max-width: 82.4rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }

          .links {
            max-width: 80rem;

            li {
              &:nth-child(1n) {
                left: auto;
                padding-right: 2.2rem;
              }

              &:nth-child(10) {
                padding-right: 0;
              }

              /* &:nth-child(1n + 10) {
                left: -100vw;
              } */
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .logo-wrapper {
            top: 25.4765625px;
            width: 20rem;
          }

          .heading {
            max-width: 91.4rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            max-width: 104.4rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }

          .links {
            padding-top: 4.8rem;
            height: 14rem;
          }
        }
      }
    }
  }
`;
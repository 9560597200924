import * as React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { ColumnWidthWrapper } from "../confines-wrappers";
import Icon from "../icon";
import Link from "../link";

const StayInTheLoopWrapper = styled.div`
  .stay-in-the-loop-wrapper {
    background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "soliel"
    })};

    .stay-in-the-loop {
      position: relative;
      margin: 0 auto;
      max-width: 48rem;

      h2,
      h3 {
        margin: 0;
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
      }

      h2 {
        margin: 0;
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_3"
    })};
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
      }

      h3,
      .copy {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "regular"
    })};
      }

      h3 {
        position: absolute;
        left: -99999rem;
        text-indent: -99999rem;
      }

      .copy {
        margin: 0;
        padding-top: 0.8em;
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
      }

      .newsletter-signup-form {
        padding-top: 2.4rem;

        .field-row {
          padding-top: 2rem;

          &:first-child {
            padding-top: 0;
          }
        }

        input[type="email"] {
          padding: 1.6rem 1.8rem;
          width: 100%;
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
          background: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};
          border: none;
          outline: none;

          &::-webkit-input-placeholder {
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
          }

          &::-moz-placeholder {
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
          }

          &:-ms-input-placeholder {
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
          }

          &:-moz-placeholder {
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
          }

          &::placeholder {
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
          }
        }

        button {
          display: inline-block;
          padding: 0.4rem 0 0.4rem;
          color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
          background: transparent;
          outline: none;
          border: transparent;
          border-bottom: 0.2rem solid
            ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColorLight"
    })};
          cursor: pointer;

          &:hover {
            border-bottom-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
          }
        }
      }

      .privacy-policy-text {
        margin: 0;
        padding-top: 3.2rem;
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_3"
    })};
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
        line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
      }

      .icon {
        display: none !important;
        position: absolute;
        bottom: 0;
        right: 4.2rem;
      }
    }
  }

  @media (min-width: 600px) {
    .stay-in-the-loop-wrapper {
      .stay-in-the-loop {
        h2 {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
        }

        .icon {
          display: inline-block !important;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .stay-in-the-loop-wrapper {
      .stay-in-the-loop {
        max-width: none;
        overflow: hidden;

        .column-left,
        .column-right {
          width: 50%;
        }

        .column-left {
          float: left;
          clear: left;
          padding-right: 2%;
        }

        .column-right {
          float: right;
          clear: right;
          padding-left: 2%;
        }

        h2 {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
        }

        h3,
        .copy {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
        }

        h3 {
          position: static;
          padding-top: 2rem;
          text-indent: 0;
        }

        .newsletter-signup-form {
          padding-top: 3rem;

          .field-row {
            padding-top: 2.8rem;
          }

          input[type="email"] {
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
        }

        .icon {
          position: static;
          box-sizing: content-box;
          padding-top: 4.2rem;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .stay-in-the-loop-wrapper {
      .stay-in-the-loop {
        h2 {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
        }

        h3,
        .copy {
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
        }

        .newsletter-signup-form {
          input[type="email"] {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
          }

          button {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
          }
        }
      }
    }
  }
`;

/**
 * Stay in the loop class component
 */
const StayInTheLoop = () => {
  return (
    <StayInTheLoopWrapper className="stay-in-the-loop-container">
      <div className="content-container stay-in-the-loop-wrapper">
        <ColumnWidthWrapper>
          <div className="stay-in-the-loop">
            <div className="column-left">
              <h2>
                Stay in the loop
              </h2>

              <p className="copy">
                Advice and insights for innovators and change-makers
              </p>
            </div>

            <div className="column-right">
              <h3>
                Newsletter sign up
              </h3>

              <form
                className="newsletter-signup-form"
                action="https://gravitywell.us3.list-manage.com/subscribe/post"
                method="POST"
              >
                <div className="field-row">
                  <input
                    type="email"
                    name="MERGE0"
                    placeholder="Enter your email"
                  />
                </div>

                <div className="field-row">
                  <input
                    type="hidden"
                    name="u"
                    value="c18fa34a44acaa24637bbd5d1"
                  />

                  <input
                    type="hidden"
                    name="id"
                    value="6b2d541b64"
                  />

                  <button
                    type="submit"
                    name="submit"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>

            <div className="column-left">
              <p className="privacy-policy-text">
                <span className="off-screen">
                  Click here to
                  {" "}
                </span>
                View our

                {" "}

                <Link
                  type="inline"
                  href="/privacy-policy"
                >
                  privacy policy
                </Link>
                .
              </p>

              <Icon
                className="icon"
                icon="satellite"
                size={6}
              />
            </div>
          </div>
        </ColumnWidthWrapper>
      </div>
    </StayInTheLoopWrapper>
  );
};

export default StayInTheLoop;
